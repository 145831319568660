/* @import url("../assets/Exo2-VariableFont_wght.ttf"); */

@font-face {
    font-family: "Klavika";
    font-style: normal;
    font-weight: 400;
    font-synthesis: style;
    text-rendering: auto;
    src: url("../assets/fonts/Klavika-Regular.otf");
    /* IE9 Compat Modes */
    src: local("Klavika"), local("Klavika"), url("../assets/fonts/Klavika-Regular.otf") format("opentype");
    /* Safari, Android, iOS */
}

@font-face {
    font-family: "Klavika";
    font-style: bold;
    font-weight: 700;
    font-synthesis: style;
    text-rendering: auto;
    src: url("../assets/fonts/Klavika-Medium.otf");
    /* IE9 Compat Modes */
    src: local("Klavika"), local("Klavika"), url("../assets/fonts/Klavika-Medium.otf") format("opentype");
    /* Safari, Android, iOS */
}

html {
    height: stretch;
    height: -webkit-fill-available;
}

body {
    font-family: "Klavika";
    font-synthesis: style;
    text-rendering: auto;
    height: 100%;
    min-height: stretch;
    min-height: -webkit-fill-available;
}

#root {
    min-height: 100%;
    min-height: -webkit-fill-available;
    position: relative;
}

button:focus {
    outline: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
h1,
h2,
h3,
h4,
h5 {
    margin-bottom: 0;
    font-weight: bold;
    line-height: 1.2;
}

h1 {
    font-size: 44px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 24px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    color: #696969;
}

p {
    font-size: 16px;
}

label {
    font-size: 14px;
    margin-bottom: 0.2rem;
}

.modalStyleOnboarding {
    max-width: 61.14% !important;
    width: 61.14%;
    min-width: 270px;
    background: #ffffff;
    border-radius: 10px !important;
    padding-top: 2%;
    padding-right: 1.5%;
    padding-bottom: 0.5% !important;
    padding-left: 1%;
    margin-bottom: 0px !important;
    /* height: 57.71% !important;
    max-height: 57.71vh !important;
    min-height: 490px; */
    flex-wrap: wrap;
    display: flex;
}
