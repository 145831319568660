.contextMenu {
    position: fixed;
    border: 0.5px solid #959595;
    cursor: pointer;
    background: #f6ea98;
    padding: 5px;
    z-index: 1000;
}

.contextMenu-Table {  
    margin-bottom: 0;
}

table.contextMenu-Table td {
    padding: 5px;
    font-size: 14px;
}

table.contextMenu-Table td:hover {
    padding: 5px;
    color:white;
    font-size: 14px;
}

table.contextMenu-Table :not(:last-child)
{
    border-bottom: 0.5px solid #959595;
}